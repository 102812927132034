/*
>> Theme constant containing theme specs
*/

export const assurantTheme = {
  // colors
  borderColor: 'border-[#3B85DB]',
  bgColor: 'bg-[#3B85DB]',
  textColor: 'text-[#3B85DB]',
  textHoverColor: 'hover:text-[#3B85DB]',

  //constants
  logo: `${process.env.REACT_APP_IMGIX_URL}/assurant/AssurantEVOneChargingLogo.webp`,
  calendlyURL: 'https://calendly.com/assurant-evone/virtual-pre-installation-appointment',

  navContent: {
    ctaText: 'Schedule Your Installation',
    ctaLink: '/schedule'
  },

  headerContent: {
    home: {
      title: `The Easy Button To Get Your EV Charger Installed`,
      subtitle: `Ready to get your EV Charger installed? You're in the right place. Let's start with a free consultation and estimate customized for your home.`,
      ctaLink: '/schedule',
      ctaText: `Schedule Your Installation`,
      bgClass: `bg-home-header`
    },
    howItWorks: {
      title: `Ready, Set, Charge: How We Make EV Charging Easy`,
      subtitle: `We make the transition to electric driving seamless with our end-to-end charger installation process.`,
      ctaLink: '/schedule',
      ctaText: `Schedule Your Installation`,
      bgClass: `bg-hiw-header`
    },
    about: {
      title: `Empowering Your EV Journey`,
      subtitle: `Learn about our mission to make EV charger installation simple and friendly.`,
      ctaLink: '/schedule',
      ctaText: `Schedule Your Installation`,
      bgClass: `bg-about-header`
    }
  },

  stepsBlockContent: {
    pageHeading: 'What To Expect & How It Works',
    blocks: [
      {
        id: 1,
        title: `Remote Consultation`,
        desc: `Our team will perform a remote video consultation where you'll show us your electrical setup and desired charger location. We will assessyour specific needs and provide guidance.`
      },
      {
        id: 2,
        title: `Package Confirmation`,
        desc: `Once our assessment is complete we'll follow up to confirm your selected package, explain permit requirements, and schedule your installation with a certified technician.`
      },
      {
        id: 3,
        title: `Installation`,
        desc: `We'll coordinate the installation appointment, handle all permits, connect your charger to the Wi-Fi network (if applicable), ensure you're up and running and schedule a final inspection.`
      },
      {
        id: 4,
        title: `Charge & Support`,
        desc: `With the installation complete, you're all set to start charging your EV. Our service continues after installation and you can call our support line for any questions or concerns.`
      }
    ],
    ctaText: `Schedule Your Installation`,
    ctaLink: '/schedule'
  },

  howItWorksContent: {
    blocks: [
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/rc.webp`,
        content: ` <h1 class="text-[24px] font-bold text-black">Remote Consultation</h1>
        <p class="text-lg leading-6 text-black max-w-md">
          The journey starts with a simple call from our care agents. We confirm your details and understand your
          vehicle and charger specifics.
        </p>
        <p class="text-lg leading-6 text-black max-w-md">
          Then, we arrange a video call where you'll show us your electrical panel, charger placement, and other
          important site details. These details help us get a clear idea of your specific installation
          requirements.
        </p>
        <p class="text-lg leading-6 text-black max-w-md">
          Based on this, we provide a detailed quote or schedule an in-person assessment within 48 hours, aiming
          for a seamless installation process.
        </p>`
      },
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/quote.webp`,
        content: `<h1 class="text-[24px] font-bold text-black">Package Confirmation</h1>
        <p class="text-lg leading-6 text-black max-w-md">
          After the consultation, we'll follow up with a confirmation of your selected package, explain permit
          requirements, and we'll schedule the installation with a certified electrician.
        </p>`
      },
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/installation.webp`,
        content: `<h1 class="text-[24px] font-bold text-black">Installation</h1>
        <p class="text-lg leading-6 text-black max-w-md">
          Our electrician arrives on the scheduled day, armed with all necessary permits, to install your EV
          charger according to the agreed-upon plan.
        </p>
        <div>
          <h1 class="text-lg text-black max-w-md mb-1">Our service goes beyond just installation:</h1>
          <ul class="list-disc text-lg pl-8 leading-6 gap-y-2 flex flex-col">
            <li>We help connect your charger to your Wi-Fi network if applicable.</li>
            <li>We provide support with setting up the necessary mobile app if needed.</li>
            <li>We assist in locating any required model or serial numbers for future reference.</li>
          </ul>
        </div>
        <p class="text-lg leading-6 text-black max-w-md">
          After completing the installation and running all necessary tests, we schedule a final inspection to
          ensure everything is done right.
        </p>`
      },
      {
        imageURL: `${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/charge.webp`,
        content: `<h1 class="text-[24px] font-bold text-black">Charge & Support</h1>
        <p class="text-lg leading-6 text-black max-w-md">
          Once you approve the final inspection, your EV charging journey begins! We send the invoice via email,
          which you can pay using convenient digital payment methods.
        </p>
        <p class="text-lg leading-6 text-black max-w-md">
          Our service continues after the installation. Have questions about your new EV charger? We've got you
          covered. Our dedicated care agents are always just a phone call away, ready to assist.
        </p>
        <p class="text-lg leading-6 text-black max-w-md">
          At MyChargerInstall, we're not just about setting you up with EV charging - we're your long-term
          partner, helping to make your electric driving experience smooth and enjoyable.
        </p>`
      }
    ]
  },

  preFooterContent: {
    title: 'Ready To Get Started?',
    desc: `Take the first step towards hassle-free EV charging at home by scheduling your installation with MyChargerInstall today.`,
    ctaText: `Schedule Your Installation`,
    ctaLink: '/schedule'
  },

  footerContent: {
    footerLinks: [
      { ctaLink: '/about', ctaText: 'About' },
      { ctaLink: '/how-it-works', ctaText: 'How It Works' },
      { ctaLink: '/schedule', ctaText: 'Schedule Installation' }
    ]
  },

  carouselContent: {
    ctaLink: '/schedule',
    ctaText: 'Get Started'
  },

  keyPointsContent: {
    bgClass: 'bg-section-4',
    pageHeading: 'What Makes Us Different',
    blocks: [
      {
        id: 1,
        title: `We're With You From Start To Finish`,
        desc: `We're here to educate and walk you through every step of the process. Our ability to remotely consult with you and determine everything needed to install your EV charger saves you time. Most importantly, it ensures we can provide as much transparency as possible for your estimate, like changes in the electrical box or additional wiring.`
      },
      {
        id: 2,
        title: `We Back Up Every Installation`,
        desc: `Our certified technicians thoroughly inspect and test the charger's installation and operation. While the technician is on site, we will work with you to ensure the installation goes as smoothly as possible, ensuring you are fully confident and satisfied with your new charger's functionality.`
      },
      {
        id: 3,
        title: `Our White Glove Approach To Service`,
        desc: `Above all else, we pride ourselves in providing everyone we interact with an outstanding experience. Taking away complexity and bringing satisfaction to your everyday life is a core tenant of our company. This approach runs through every aspect of our business, so we treat every interaction with the utmost care and respect.`
      }
    ]
  },

  scheduleContent: {
    showNavLinks: false,
    pageHeading: 'Schedule Your Pre-Installation Appointment',
    calendlyContent: `<h1 class="font-bold leading-[36px] text-[24px] text-[#1C2839] max-w-sm"> Virtual Pre-Installation Appointment </h1> <div class="bg-[#3B85DB] w-16 h-1 my-6"></div> <p class="text-sm text-[#272727] leading-6 mb-4 max-w-xs font-medium"> We work with an experienced network of licensed technicians specializing in EV chargers to provide a seamless installation experience. </p> <p class="text-sm text-[#272727] leading-6 mb-4 max-w-xs font-medium"> During this virtual visit, your installer will assess installation readiness and discuss your preferences. </p> <ul class="list-disc text-sm text-[#272727] leading-6 mb-4 pl-6 max-w-xs font-medium"> <li>Have a smartphone or tablet ready</li> <li>Know the location of your electrical panel/box</li> <li>Identify your preferred charger location</li> </ul> <p class="text-sm text-[#272727] leading-6 mb-4 max-w-xs font-medium"> For support, please contact ${process.env.REACT_APP_PHONE} </p>`
  }
};

export default {
  assurantTheme
};
