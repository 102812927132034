import { bestbuyTheme } from './bestbuy';
import { assurantTheme } from './assurant';
import { schumacherTheme } from './schumacher';
import { mciTheme } from './mci';

export const themeWrapper = () => {
  return {
    bestbuyTheme,
    assurantTheme,
    schumacherTheme,
    mciTheme
  };
};

export default themeWrapper;
