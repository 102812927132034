import React, { useContext } from 'react';
import { ThemeContext } from '../App';

export const Footer = ({ showlinks }) => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div className="bg-[#1f1f1f] w-full">
      <div className="w-full max-w-[1350px] mx-auto flex flex-col gap-y-2 py-14 lg:py-10">
        {showlinks && (
          <ul className="footer-links flex flex-col lg:flex-row items-start lg:items-center text-sm gap-x-4 gap-y-2 ml-5 text-white">
            {themeConfig.footerContent.footerLinks.map((item) => {
              return (
                <>
                  <li className={`underline cursor-pointer hover:no-underline ${themeConfig.textHoverColor}`}>
                    <a href={item.ctaLink}>{item.ctaText}</a>
                  </li>
                  <li className="hidden lg:flex">|</li>
                </>
              );
            })}
          </ul>
        )}
        <hr className={`border-2 ${themeConfig.borderColor} w-full max-w-xs lg:max-w-[1310px] ml-5 lg:mx-auto my-4`} />
        <div className="mr-auto flex flex-col lg:flex-row lg:items-center w-full">
          <div className="order-2 lg:order-1">
            <ul className="flex flex-col lg:flex-row items-start lg:items-center text-xs gap-x-2 gap-y-2 mb-2 ml-5 text-white">
              <li className={`underline cursor-pointer hover:no-underline ${themeConfig.textHoverColor}`}>
                <a href="https://greenmarbles.com/privacy-policy">Privacy Policy</a>
              </li>
              <li className="hidden lg:flex">|</li>
              <li className={`underline cursor-pointer hover:no-underline ${themeConfig.textHoverColor}`}>
                <a href="https://greenmarbles.com/terms-conditions">Terms &amp; Conditions</a>
              </li>
            </ul>
            <p className="text-white text-xs ml-5 mb-1">&copy; 2023 GreenMarbles, LLC. All Rights Reserved.</p>
            <p className="text-white text-xs max-w-xs lg:max-w-none ml-5">
              MyChargerInstall & MyChargerInstall.com are services of GreenMarbles, LLC
            </p>
          </div>
          <a href="/" className="lg:ml-auto order-1 lg:order-2">
            <img
              src={`${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/MyChargerInstallLogo1White.webp`}
              className="w-[270px] mb-2"
              alt="mychargerlogo logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
