import React, { useContext, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import FAQ from '../Components/Faq';
import ErrorResponse from '../Components/Utils/ErrorResponse';
import SuccessResponse from '../Components/Utils/SuccessResponse';
import { addLeads } from '../Components/Helpers/Leads';
import { ThemeContext } from '../App';

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required('Firstname is required.'),
  lastname: Yup.string().required('Lastname is required.'),
  email: Yup.string().email().required('Email is required.'),
  phone: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10, 'Phone number is not valid'),
  company: Yup.string().required('Company is required.'),
  comments: Yup.string()
});

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  company: '',
  comments: ''
};

export const Contact = ({ showNav }) => {
  const themeConfig = useContext(ThemeContext);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [networkErr, setNetworkErr] = useState(false);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      addLeadsInfo(values);
    }
  });

  const addLeadsInfo = async (values) => {
    setIsFormSubmitting(true);
    await addLeads(values).then((response) => {
      if (!response) {
        setIsFormSubmitting(false);
        setNetworkErr(true);
      } else {
        setIsFormSubmitting(false);
        setSubmitSuccess(true);
        formik.resetForm({ values: '' });
      }
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    formik.handleSubmit();
  };

  return (
    <div className="Contact">
      <Navbar showlinks={showNav === 'false' ? false : true} />
      <div className="section-4 bg-schedule-header bg-top lg:bg-center bg-cover bg-no-repeat mx-auto flex flex-col justify-center items-center mb-16 relative mt-[138px]">
        <div className="w-full h-full block bg-black bg-opacity-50 absolute"></div>
        <div className="z-10 w-full max-w-[360px] md:max-w-xl lg:max-w-[1180px]">
          <h1 className="text-3xl mb-8 md:mb-0 mt-16 text-white font-bold text-center">Let's Talk About EV Charging</h1>
          <div className="bg-[#F4F4F4] gap-y-16 w-full flex flex-col lg:flex-row my-10 lg:mb-20 mx-auto rounded-lg">
            {/* block 1 */}
            <div className="flex justify-start w-full">
              <img
                src={`${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/contact_block_img.webp`}
                alt="about image"
                className="rounded-l-lg w-full"
              />
            </div>
            {/* block 2 */}
            <div className="flex items-center justify-center w-full pb-8 lg:pb-0">
              <div className="flex flex-col w-full gap-y-5 max-w-xs md:max-w-md lg:max-w-[680px] mx-0">
                <form className="mx-auto max-w-[480px] w-full lg:mt-2" onSubmit={handleFormSubmit}>
                  <div className={`flex flex-col md:flex-row text-lg`}>
                    <div className="flex flex-col w-full mb-6 md:mb-0 relative">
                      <label className="text-base font-medium mb-1">First Name</label>
                      <input
                        type="text"
                        placeholder="First Name*"
                        className="bg-white p-4 w-full md:w-[95%] text-base h-[45px] mr-0 md:mr-6"
                        name="firstname"
                        onChange={formik.handleChange}
                        value={formik.values.firstname}
                      />
                      {formik.errors.firstname && formik.touched.firstname && (
                        <div className="text-red-500 absolute -bottom-[24px] text-sm mt-1">
                          {formik.errors.firstname}
                        </div>
                      )}
                    </div>
                    <div className="flex flex-col w-full relative">
                      <label className="text-base font-medium mb-1">Last Name</label>
                      <input
                        type="text"
                        placeholder="Last Name*"
                        className="bg-white p-4 w-full text-base h-[45px]"
                        name="lastname"
                        onChange={formik.handleChange}
                        value={formik.values.lastname}
                      />
                      {formik.errors.lastname && formik.touched.lastname && (
                        <div className="text-red-500 absolute -bottom-[24px] text-sm mt-1">
                          {formik.errors.lastname}
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex flex-col md:flex-row text-lg ${
                      (formik.errors.firstname && formik.touched.firstname) ||
                      (formik.errors.lastname && formik.touched.lastname)
                        ? 'mt-8'
                        : 'mt-4'
                    }`}
                  >
                    <div className="flex flex-col w-full mb-6 md:mb-0 relative">
                      <label className="text-base font-medium mb-1">Email</label>
                      <input
                        type="email"
                        placeholder="E-mail Address*"
                        className="bg-white p-4 w-full md:w-[95%] text-base h-[45px] mr-0 md:mr-6"
                        name="email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <div className="text-red-500 absolute -bottom-[24px] text-sm mt-1">{formik.errors.email}</div>
                      )}
                    </div>
                    <div className="flex flex-col w-full relative">
                      <label className="text-base font-medium mb-1">Phone Number</label>
                      <input
                        type="tel"
                        placeholder="Phone Number"
                        className="bg-white p-4 w-full text-base h-[45px]"
                        name="phone"
                        onChange={formik.handleChange}
                        maxLength={10}
                        value={formik.values.phone}
                      />
                      {formik.errors.phone && formik.touched.phone && (
                        <div className="text-red-500 absolute -bottom-[24px] text-sm mt-1">{formik.errors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex flex-col md:flex-row text-lg relative ${
                      (formik.errors.email && formik.touched.email) || (formik.errors.phone && formik.touched.phone)
                        ? 'mt-8'
                        : 'mt-4'
                    }`}
                  >
                    <div className="flex flex-col w-full">
                      <label className="text-base font-medium mb-1">Company/Organization</label>
                      <input
                        type="text"
                        placeholder="Company*"
                        className="bg-white p-4 w-full text-base h-[45px]"
                        name="company"
                        onChange={formik.handleChange}
                        value={formik.values.company}
                      />
                      {formik.errors.company && formik.touched.company && (
                        <div className="text-red-500 absolute -bottom-[24px] text-sm mt-1">{formik.errors.company}</div>
                      )}
                    </div>
                  </div>
                  <div
                    className={`flex flex-col md:flex-row text-lg ${
                      formik.errors.company && formik.touched.company ? 'mt-8' : 'mt-4'
                    } relative`}
                  >
                    <div className="flex flex-col w-full">
                      <label className="text-base font-medium mb-1">Additional Information</label>
                      <textarea
                        type="text"
                        placeholder="Please share any additional information or questions with us here."
                        className="bg-white text-base p-4 w-full resize-none"
                        rows={3}
                        name="comments"
                        onChange={formik.handleChange}
                        value={formik.values.comments}
                      />
                      {formik.errors.comments && formik.touched.comments && (
                        <div className="text-red-500 text-xs">{formik.errors.comments}</div>
                      )}
                    </div>
                  </div>

                  {/* {Object.keys(formik.errors).length > 0 ? (
                    <p className="flex items-center justify-center mx-auto w-auto px-9 py-3 mt-4 border-2 border-ffcd00 text-white rounded">
                      Please fill out the required fields
                    </p>
                  ) : (
                    ''
                  )} */}
                  <div className="flex items-center mt-10 mb-10 md:mt-5 md:mb-0">
                    <button
                      type="submit"
                      className={`${
                        isFormSubmitting ? 'bg-gray-400 pointer-events-none' : themeConfig.bgColor
                      } h-[45px] w-full md:w-32 text-[16px] text-white rounded-md font-bold`}
                    >
                      Submit
                    </button>
                    {isFormSubmitting && (
                      <div className="rounded-md p-2 px-3 h-[45px]">
                        <i className="fa fa-spinner fa-spin mx-auto text-lg text-gray-500"></i>
                      </div>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {submitSuccess && <SuccessResponse setSubmitSuccess={setSubmitSuccess} closeEvent="close" />}
        {networkErr && <ErrorResponse setNetworkErr={setNetworkErr} closeEvent="close" />}
      </div>
      <FAQ />
      <Footer />
    </div>
  );
};

export default Contact;
