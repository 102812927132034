import React from 'react';

export const ErrorResponse = ({ setNetworkErr, closeEvent }) => {
  const handleCloseEvent = () => {
    if (closeEvent === 'close') {
      setNetworkErr(false);
    } else if (closeEvent === 'reload') {
      window.location.reload();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-40" onClick={handleCloseEvent}>
      <div className="text-gray-700 bg-white my-[200px] p-6 max-w-lg w-full m-auto rounded-md relative border-t-8 border-yellow-400">
        <div
          className="absolute -right-3 -top-4 w-6 h-6 bg-white border border-gray-700 rounded-full flex items-center justify-center cursor-pointer"
          onClick={handleCloseEvent}
        >
          <i className="fa fa-times"></i>
        </div>
        <p className="text-left max-w-md">
          We experienced a problem handling your request. Please try again in few minutes. <br />
          <br /> If the problem persists, please contact Customer Care at{' '}
          <a className="no-underline font-bold" href={`tel:${process.env.REACT_APP_PHONE}`}>
            {process.env.REACT_APP_PHONE}
          </a>{' '}
          for assistance.
        </p>
      </div>
    </div>
  );
};

export default ErrorResponse;
