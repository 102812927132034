const currentTheme = process.env.REACT_APP_THEME;

export let QA;

if (currentTheme === 'assurant') {
  QA = [
    {
      uuid: 1,
      heading: 'Are your technicians licensed, certified and vetted?',
      content: `Absolutely. Every technician within MyChargerInstall's network is licensed and certified. Our thorough vetting process will ensure you can expect professionalism and expertise. We're committed to these high standards to provide safe and high-quality EV charger installations for our customers.`
    },
    {
      uuid: 2,
      heading: 'How long will it take to have my EV Charger installed?',
      content: `While the exact time for an EV charger installation can vary based on specific circumstances and requirements, a standard installation typically takes 2 to 4 hours. This timeframe includes assessing the installation site, setting up the charger, testing it to ensure it's working correctly, and teaching you how to use it.`
    },
    {
      uuid: 3,
      heading: 'Will I need to upgrade my electrical panel or obtain permits?',
      content: `The need to upgrade your electrical panel or obtain permits depends on the current capacity of your electrical panel, local codes, and regulations. During the remote consultation, the MyChargerInstall team will assess your situation and provide personalized advice. If an upgrade or permits are necessary, we'll guide you through the process and coordinate everything to ensure a smooth and compliant installation.`
    },
    {
      uuid: 4,
      heading: 'What happens if my EV Charger breaks after you install it?',
      content: `If you encounter any issues with your EV charger after installation, please don't hesitate to contact us at <a href="mailto:assurant@mychargerinstall.com">MyChargerInstall</a>. Our dedicated support team will diagnose what is causing the problem and guide you through whatever next steps are necessary.`
    },
    {
      uuid: 5,
      heading: `Can you overcharge an EV? How does Fast-Charging affect my EV's battery?`,
      content: `Built-in technology prevents charging beyond capacity and modern electric vehicles come equipped with smart charging technology that prevents the battery from taking in more power than it can handle. Fast charging is beneficial for quick power-ups, but excessive use can strain the battery over time due to heat generation. It's always better to use standard home charging day-to-day and fast charging when necessary.
      `
    },
    {
      uuid: 6,
      heading: 'What do I need to do to prepare for my EV Charger installation?',
      content: `To prepare for your EV Charger installation, you'll need to identify your preferred installation location, ensure a strong Wi-Fi connection if your charger has intelligent features, be aware of your electrical panel's capacity, and clear the installation area for easy access. Check out our "How It Works Page" for additional information.`
    },
    {
      uuid: 7,
      heading: 'How does Assurant work with MyChargerInstall?',
      content: `You purchased the Assurant EVOne Charger product and Assurant partners with certified technicians through MyChargerInstall to make your EV charging experience as seemless as possible.`
    }
  ];
} else {
  QA = [
    {
      uuid: 1,
      heading: 'Are your installers licensed, certified and vetted?',
      content: `Absolutely. Every installer within MyChargerInstall's network is licensed and certified. They also undergo a thorough vetting process for their professionalism and expertise. We're committed to these high standards to ensure safe and high-quality EV charger installations for everyone one of our customers.`
    },
    {
      uuid: 2,
      heading: 'How long will it take to have my EV Charger installed?',
      content: `While the exact time for an EV charger installation can vary based on specific circumstances and requirements, a standard installation typically takes 2 to 4 hours. This timeframe includes assessing the installation site, setting up the charger, testing it to ensure it's working correctly, and teaching you how to use it.`
    },
    {
      uuid: 3,
      heading: 'Will I need to upgrade my electrical panel or pull permits?',
      content: `The need to upgrade your electrical panel or pull permits depends on the current capacity of your electrical panel, local codes, and regulations. During the remote consultation, the MyChargerInstall team will assess your situation and provide personalized advice. If an upgrade or permits are necessary, we'll guide you through the process and coordinate everything to ensure a smooth and compliant installation.`
    },
    {
      uuid: 4,
      heading: 'What happens if my EV Charger breaks after you install it?',
      content: `If you encounter any issues with your EV charger after installation, please don't hesitate to contact us at MyChargerInstall. Our dedicated support team will diagnose what is causing the problem and guide you through whatever next steps are necessary.`
    },
    {
      uuid: 5,
      heading: `Can you overcharge an EV? Does Fast-Charging hurt my EV's battery?`,
      content: `You can't typically overcharge an EV, as built-in technology prevents charging beyond capacity. Modern electric vehicles come equipped with smart charging technology that prevents the battery from taking in more power than it can handle. Fast charging is beneficial for quick power-ups, but excessive use can strain the battery over time due to heat generation. It's always better to use standard home charging day-to-day and fast charging when necessary.`
    },
    {
      uuid: 6,
      heading: 'What do I need to do to prepare for my EV Charger installation?',
      content: `To prepare for your EV Charger installation, you'll need to identify your preferred installation location, ensure a strong Wi-Fi connection if your charger has intelligent features, be aware of your electrical panel's capacity, and clear the installation area for easy access. Check out our "How It Works Page" for additional information.`
    },
    {
      uuid: 7,
      heading: 'How much does a typical installation cost, and are there any hidden fees I should be aware of?',
      content: `The cost of EV charger installation can vary depending on factors like your charger model, installation location, and the condition of your electrical panel. At MyChargerInstall, we'll evaluate these factors during our remote consultation to provide a personalized quote. We aim to be as transparent as possible, so our quote will clearly outline all costs, including labor, possible panel upgrades, permits, and necessary materials. There are no hidden fees; the quote you receive is the final price.`
    }
  ];
}

export default QA;
