import React, { useContext } from 'react';
import { ThemeContext } from '../App';

export const Section6 = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div className="section-6 bg-section-6 bg-cover bg-center bg-no-repeat mx-auto flex flex-col justify-center items-center h-[554px] mt-16">
      <div className="bg-[#000000] bg-opacity-50 h-full w-full flex flex-col justify-center items-center gap-y-6">
        <h1 className="text-2xl lg:text-3xl mx-auto max-w-xs lg:max-w-full text-center text-white font-bold">
          {themeConfig.preFooterContent.title}
        </h1>
        <p className="text-sm lg:text-lg leading-6 font-medium text-white max-w-[350px] lg:max-w-3xl text-center">
          {themeConfig.preFooterContent.desc}
        </p>
        <a href={themeConfig.preFooterContent.ctaLink}>
          <button className={`${themeConfig.bgColor} px-8 py-3 max-w-xs rounded-md font-bold text-white`}>
            {themeConfig.preFooterContent.ctaText}
          </button>
        </a>
      </div>
    </div>
  );
};

export default Section6;
