import React, { useContext } from 'react';

import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import Carousel from '../Components/Carousel';
import StepsBlock from '../Components/StepsBlock';
import Section6 from '../Components/Section6';
import Footer from '../Components/Footer';
import { ThemeContext } from '../App';
import HorizontalSplitBlock from '../Components/HorizontalSplitBlock';

export const HowItWorks = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <>
      <Navbar showlinks={true} />
      <Header
        title={themeConfig.headerContent.howItWorks.title}
        subtitle={themeConfig.headerContent.howItWorks.subtitle}
        ctaLink={themeConfig.headerContent.howItWorks.ctaLink}
        ctaText={themeConfig.headerContent.howItWorks.ctaText}
        bgClass={themeConfig.headerContent.howItWorks.bgClass}
      />
      <StepsBlock showlinks={false} showDescription={false} />
      {themeConfig.howItWorksContent.blocks.map((item) => {
        return <HorizontalSplitBlock imageURL={item.imageURL} content={item.content} />;
      })}
      <Carousel heading={`See What Our Customers Have To Say`} showCta={false} />
      <Section6 />
      <Footer />
    </>
  );
};

export default HowItWorks;
