import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

import { QA } from '../Components/Data/QA';

export const FAQ = ({ mode }) => {
  return (
    <>
      <div className="max-w-[320px] md:max-w-[1440px] mx-auto flex flex-col justify-center items-center">
        <h1
          className={`text-xl md:text-3xl font-bold text-center mb-8 md:mb-12 ${
            mode === 'dark' ? 'text-white' : 'text-[#585858]'
          }`}
        >
          Frequently Asked Questions
        </h1>
      </div>
      <div className="flex mx-auto border border-[#585858] md:rounded-xl mb-16 max-w-[340px] md:max-w-[680px] lg:max-w-[1100px] bg-white">
        <Accordion className="w-full faq-accordion" allowZeroExpanded>
          {QA.map((item) => (
            <AccordionItem key={item.uuid}>
              <AccordionItemHeading>
                <AccordionItemButton className="flex justify-between items-center cursor-pointer text-[#585858] font-semibold text-base md:text-[20px] px-8 md:px-10 py-9 border-b border-[#585858]">
                  <p className="max-w-3xl">{item.heading}</p>
                  <AccordionItemState>
                    {({ expanded }) => {
                      return <i className={`fa ${expanded ? 'fa-minus' : 'fa-plus'} text-[#BDBDBD]`} />;
                    }}
                  </AccordionItemState>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className="px-10 py-9 bg-[#eeeeee] border-b border-[#585858] text-base font-medium">
                <p className="max-w-4xl" dangerouslySetInnerHTML={{ __html: item.content }}></p>
              </AccordionItemPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </>
  );
};

export default FAQ;
