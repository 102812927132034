import React, { useContext } from 'react';
import { ThemeContext } from '../../App';

export const SuccessResponse = ({ setSubmitSuccess, closeEvent }) => {
  const themeConfig = useContext(ThemeContext);

  const handleCloseEvent = () => {
    if (closeEvent === 'close') {
      setSubmitSuccess(false);
    } else if (closeEvent === 'reload') {
      window.location.reload();
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full z-50 bg-black bg-opacity-40" onClick={handleCloseEvent}>
      <div className="text-gray-700 bg-white my-[200px] px-10 py-12 h-auto w-auto lg:w-full max-w-[600px] mx-5 lg:mx-auto rounded-md relative">
        <p className="text-center text-lg mb-8 mx-auto max-w-[480px]">
          Thank you for your interest in MyChargerInstall. <br />
          One of our team members will reach out to you shortly regarding your inquiry.
        </p>

        <button
          type="submit"
          className={`w-max px-10 h-10 mx-auto flex items-center justify-center text-white ${themeConfig.bgColor} rounded-md font-bold uppercase text-sm`}
          onClick={handleCloseEvent}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessResponse;
