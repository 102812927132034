import React, { useContext } from 'react';

import { ThemeContext } from '../App';

export const StepsBlock = ({ showlinks, showDescription }) => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div className="section-1 max-w-[320px] md:max-w-[1440px] mx-auto flex flex-col justify-center items-center my-16 md:my-20">
      <h1 className="text-2xl md:text-3xl max-w-xs md:max-w-full text-center text-[#585858] font-bold">
        {themeConfig.stepsBlockContent.pageHeading}
      </h1>
      <div className="flex flex-col md:flex-row justify-between w-full max-w-[1380px] mx-auto mt-10 md:my-20 relative">
        <div
          className={`hidden md:block connector h-2 w-[980px] ${themeConfig.bgColor} mx-auto left-0 right-0 top-12 absolute -z-10`}
        ></div>
        {themeConfig.stepsBlockContent.blocks.map((item) => {
          return (
            <div className="flex flex-col justify-start items-center w-full mx-5 mb-20 md:mb-0" key={item.id}>
              <div
                className={`border-[10px] ${themeConfig.borderColor} rounded-full h-[100px] w-[100px] flex items-center justify-center mb-6 bg-white`}
              >
                <span className="font-bold text-3xl text-[#363636]">{item.id}</span>
              </div>
              <h1 className="text-[24px] font-medium text-[#363636] mb-3">{item.title}</h1>
              {showDescription && <p className="text-base text-[#727272] text-center font-medium">{item.desc}</p>}
            </div>
          );
        })}
      </div>
      {showlinks && (
        <div className="flex flex-col lg:flex-row gap-6">
          <a href="/how-it-works">
            <button className="bg-[#575757] px-4 md:px-8 py-3 w-full rounded-md font-bold text-white">
              How It Works
            </button>
          </a>
          <a href={themeConfig.stepsBlockContent.ctaLink}>
            <button className={`${themeConfig.bgColor} px-4 md:px-8 py-3 w-full rounded-md font-bold text-white`}>
              {themeConfig.stepsBlockContent.ctaText}
            </button>
          </a>
        </div>
      )}
    </div>
  );
};

export default StepsBlock;
