import React, { useContext } from 'react';
import { ThemeContext } from '../App';

export const HorizontalSplitBlock = ({ imageURL, content, wrapperMargin }) => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div
      className={`mx-auto flex flex-col justify-center items-center ${
        wrapperMargin !== undefined ? wrapperMargin : `mt-10 mb-32`
      }`}
    >
      <div className="bg-[#F4F4F4] bg-opacity-80 gap-y-16 max-w-[1100px] w-full rounded-2xl flex flex-col md:flex-row">
        {/* block 1 */}
        <div className="flex items-start justify-center w-full">
          <img src={imageURL} alt="" />
        </div>

        {/* block 2 */}
        <div className="flex items-center justify-center w-full pb-16 md:pb-0">
          <div
            className="flex flex-col w-full gap-y-5 max-w-[640px] mx-10"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default HorizontalSplitBlock;
