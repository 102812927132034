import React, { useContext } from 'react';
import { ThemeContext } from '../App';

export const Section4 = () => {
  const themeConfig = useContext(ThemeContext);

  return (
    <div
      className={`section-4 ${themeConfig.keyPointsContent.bgClass} bg-cover bg-no-repeat mx-auto flex flex-col justify-center items-center h-auto md:min-h-[950px] my-16 relative`}
    >
      <div className="flex md:hidden bg-[#363636] bg-opacity-50 w-full h-full top-0 absolute"></div>
      <div className="z-10 py-10 md:py-0">
        <h1 className="text-center text-[28px] md:text-3xl text-white mb-0 md:mb-12 font-bold">
          {themeConfig.keyPointsContent.pageHeading}
        </h1>
        <div className="md:bg-[#363636] md:bg-opacity-80 px-0 md:px-16 py-16 gap-y-16 max-w-[940px] w-full md:rounded-2xl flex flex-col">
          {themeConfig.keyPointsContent.blocks.map((item) => {
            return (
              <div className="flex flex-col md:flex-row items-start justify-center" key={item.id}>
                <div
                  className={`${themeConfig.bgColor} flex items-center justify-center text-[40px] font-bold w-20 h-20 ml-10 mb-6 md:ml-auto md:mb-0 rounded-full text-white`}
                >
                  <span>{item.id}</span>
                </div>
                <div className="flex flex-col w-full gap-y-3 max-w-[300px] md:max-w-[640px] mx-10">
                  <h1 className="text-2xl md:text-[26px] font-bold text-white">{item.title}</h1>
                  <p className="text-base leading-6 font-medium text-white">{item.desc}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Section4;
