import React, { useContext, useState } from 'react';
import Navbar from '../Components/Navbar';
import StepsBlock from '../Components/StepsBlock';
import FAQ from '../Components/Faq';
import Footer from '../Components/Footer';
import { useCalendlyEventListener, InlineWidget } from 'react-calendly';

import { ThemeContext } from '../App';

export const Schedule = () => {
  const themeConfig = useContext(ThemeContext);
  const [calendlyHeight, setCalendlyHeight] = useState('680px');
  const pageSettings = {
    hideEventTypeDetails: 1,
    hideGdprBanner: 1,
    backgroundColor: 'ffffff',
    primaryColor: '4976e6',
    textColor: '000000'
  };

  // Sets Calendly height dynamically
  useCalendlyEventListener({
    onEventTypeViewed: () => {
      if (document.body.clientWidth > 768) {
        setCalendlyHeight('680px');
      } else {
        setCalendlyHeight('640px');
      }
    },
    onDateAndTimeSelected: (e) => {
      if (document.body.clientWidth > 768) {
        setCalendlyHeight('1100px');
      } else {
        setCalendlyHeight('1100px');
      }
    }
  });

  return (
    <div className="Schedule">
      <Navbar showlinks={themeConfig.scheduleContent.showNavLinks} />
      <div className="section-4 bg-schedule-header bg-cover bg-no-repeat mx-auto flex flex-col justify-center items-center mb-16 relative mt-[138px]">
        <div className="w-full h-full block bg-black bg-opacity-30 absolute"></div>
        <div className="z-10 w-full max-w-[360px] lg:max-w-[800px]">
          <h1 className="text-3xl mb-8 md:mb-0 mt-16 text-white font-bold text-center">
            {themeConfig.scheduleContent.pageHeading}
          </h1>
          <div className="flex flex-col md:flex-row w-full my-10 lg:mb-20 mx-auto lg:bg-white">
            <div className="bg-white w-full lg:flex flex-col px-5 pt-8 lg:p-8 lg:pr-0 lg:border-r border-gray-200">
              <div
                className="flex flex-col items-start"
                dangerouslySetInnerHTML={{ __html: themeConfig.scheduleContent.calendlyContent }}
              ></div>
            </div>

            <div className="w-full">
              <InlineWidget
                url={themeConfig.calendlyURL}
                pageSettings={pageSettings}
                styles={{ height: calendlyHeight, maxHeight: calendlyHeight }}
              />
            </div>
          </div>
        </div>
      </div>
      <StepsBlock showlinks={false} showDescription={true} />
      <div className="faq-section bg-faq-bg bg-fixed bg-cover bg-no-repeat mx-auto hidden md:flex flex-col justify-center items-center">
        <div className="w-full bg-black bg-opacity-30 py-16">
          <FAQ mode="dark" />
        </div>
      </div>
      <div className="flex flex-col md:hidden">
        <FAQ />
      </div>
      <Footer showlinks={themeConfig.scheduleContent.showNavLinks} />
    </div>
  );
};

export default Schedule;
