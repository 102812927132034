// Submits lead info
export const addLeads = async (formValues) => {
  const path = process.env.REACT_APP_LEADS_API_URL + '/leads';
  const formData = {
    'first-name': formValues.firstname,
    'last-name': formValues.lastname,
    email: formValues.email,
    phone: formValues.phone,
    company: formValues.company,
    comments: formValues.comments,
    'lead-source': 'Web',
    'lead-type': 'mychargerinstall'
  };

  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-api-key': process.env.REACT_APP_LEADS_API_KEY
    },
    body: JSON.stringify(formData)
  };

  return await fetch(path, options).then((response) => {
    if (response.status !== 200) {
      return false;
    }
    return response.json();
  });
};

export default {
  addLeads
};
