import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import Home from './Pages/Home';
import Schedule from './Pages/Schedule';
import HowItWorks from './Pages/HowItWorks';
import About from './Pages/About';
import Contact from './Pages/Contact';

import themeWrapper from '../src/Components/Themes/themeWrapper';
import handleCustomEvent from './Components/Helpers/CustomEventsHandler';

export const ThemeContext = React.createContext();

export const App = () => {
  const currentTheme = process.env.REACT_APP_THEME;
  let themeConfig;

  switch (currentTheme) {
    case 'bestbuy':
      themeConfig = themeWrapper().bestbuyTheme;
      break;
    case 'assurant':
      themeConfig = themeWrapper().assurantTheme;
      break;
    case 'schumacher':
      themeConfig = themeWrapper().schumacherTheme;
      break;
    default:
      themeConfig = themeWrapper().mciTheme;
      break;
  }

  // Inititalize GA4
  useEffect(() => {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID
    };
    TagManager.initialize(tagManagerArgs);
  }, []);

  // Fire custom GA4 event on clicking Schedule Your Consultation button
  useEffect(() => {
    document.querySelector('body').addEventListener('click', (e) => {
      const el = e.target.innerHTML.toLowerCase();
      if (el === 'schedule your consultation' || el === 'get started') {
        const event = {
          name: 'schedule_button_click'
        };
        handleCustomEvent(event);
      }
    });
  });

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <Home />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/how-it-works"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <HowItWorks />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/about"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <About />
          </ThemeContext.Provider>
        }
      />
      <Route
        path="/contact"
        element={
          <ThemeContext.Provider value={themeConfig}>
            <Contact />
          </ThemeContext.Provider>
        }
      />
      {currentTheme !== 'mci' && (
        <>
          <Route
            path="/schedule"
            element={
              <ThemeContext.Provider value={themeConfig}>
                <Schedule />
              </ThemeContext.Provider>
            }
          />
        </>
      )}
    </Routes>
  );
};

export default App;
