import React, { useContext } from 'react';
import Navbar from '../Components/Navbar';
import Header from '../Components/Header';
import Carousel from '../Components/Carousel';
import StepsBlock from '../Components/StepsBlock';
import Section4 from '../Components/Section4';
import HorizontalSplitBlock from '../Components/HorizontalSplitBlock';
import FAQ from '../Components/Faq';
import Section6 from '../Components/Section6';
import Footer from '../Components/Footer';
import { ThemeContext } from '../App';

export const Home = () => {
  const currentTheme = process.env.REACT_APP_THEME;
  const themeConfig = useContext(ThemeContext);

  return (
    <div className="App">
      <Navbar showlinks={true} />
      <Header
        title={themeConfig.headerContent.home.title}
        subtitle={themeConfig.headerContent.home.subtitle}
        ctaLink={themeConfig.headerContent.home.ctaLink}
        ctaText={themeConfig.headerContent.home.ctaText}
        bgClass={themeConfig.headerContent.home.bgClass}
      />
      {currentTheme === 'mci' ? (
        <StepsBlock showlinks={true} showDescription={true} />
      ) : (
        <Carousel heading={`A Solution You Can Trust`} showCta={true} />
      )}
      <Section4 />
      {currentTheme === 'mci' ? (
        <Carousel heading={`A Solution You Can Trust`} showCta={true} />
      ) : (
        <StepsBlock showlinks={true} showDescription={true} />
      )}
      <HorizontalSplitBlock
        imageURL={`${process.env.REACT_APP_IMGIX_URL}/mychargerinstall/about1.webp`}
        content={`<h1 class="text-[24px] font-bold text-black">About MyChargerInstall</h1>
        <p class="text-lg leading-6 text-black max-w-md">
          MyChargerInstall helps you quickly get your electric vehicle charger up and running.
        </p>
        <p class="text-lg leading-6 text-black max-w-md">
          We always take a customer-focused approach, guiding you through each step - from initial consultation to
          professional installation - and even handling all the tricky paperwork. Plus, we're always here for
          support after we install your charger. We're your partner in making electric driving simple and
          stress-free.
        </p>
        <h1 class="text-lg font-semibold text-black underline">
          <a href="/about">Learn More</a>
        </h1>`}
        wrapperMargin={`my-32`}
      />
      <FAQ />
      <Section6 />
      <Footer />
    </div>
  );
};

export default Home;
